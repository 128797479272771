import axios from 'axios'
//引入Message方法
import { Message } from 'element-ui'
import router from '@/router/index.js'

console.log(process.env.VUE_APP_URL)
const service = axios.create({
  baseURL:'',
  timeout:5000,
})

//请求拦截器
service.interceptors.request.use(config => {
  return config
},err => {
  Message({message:'请求超时'})
  return Promise.reject(err)
})
//响应拦截
service.interceptors.response.use(response => {
  if (response.data.code != 200){
    if (response.data.code == 2001){
      router.push('/login')
    } else if (response.data.code == 2002){
      router.push('/login')
    } else {
      Message({
        message:response.data.message,
        type:'error',
        duration:2000
      })
    }
  }
  return response.data
},err => {
  Message({message:'网络错误'})
  return Promise.reject(err)
})

export default service
